import { post, deletes, get, downFile } from 'common/request'
export default {

  //  事件分类分页
  workList: args => get('/hotInfo/workGuideType/list', args),
  //  资讯分类分页启用状态修改
  workFlag: args => post('/hotInfo/workGuideType/update', args),
  // 新增分类
  addwork: args => post('/hotInfo/workGuideType/add', args),
  // 批量删除分类
  deleteBatch: args => deletes(`/hotInfo/workGuideType/ids`, args),
  // 删除分类
  dels: args => deletes(`/hotInfo/workGuideType/deleteById`, args),


  // 网格树
  gridTree: args => get('/scGridManagement/getTreeList', args),
  //  办事指南分页
  guideList: args => get('/hotInfo/workGuide/list', args),
  // 事件分类查询所有
  guideListall: args => get('/hotInfo/workGuideType/listAll', args),
  // 新增办事指南
  addGuide: args => post('/hotInfo/workGuide/add', args),
  // 批量删除办事指南
  deletesGuide: args => deletes(`/hotInfo/workGuide/ids`, args),
  // 删除办事指南
  delGuide: args => deletes(`/hotInfo/workGuide/deleteById`, args),
  // 编辑办事指南
  editGuide: args => post(`/hotInfo/workGuide/update`, args),
}
